<template>
  <div>
    <h1>{{this.article ? this.article.title : this.$route.meta.title}}</h1>

    <!-- <dynamic-content v-if="article" :html="article.content" class="markdown mx-0 mt-4 dynamic-content"></dynamic-content> -->
    <div v-if="article" v-html="article.content" class="markdown mx-0 mt-4 dynamic-content"> </div>
  </div>
</template>

<script>

import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "StaticContent",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      article: null,
    };
  },
  created() {
  },
  async mounted() {
    this.article = await articleService.get(this.$route.meta.slug);

    if (this.article == null) {
      // todo: redirect to 404
    }
    else {
      var meta = {
        title: this.article.title,
        description: this.article.summary,
        image: this.article.imageUrl,
      };
      EventBus.$emit('page-header-change', meta);
    }
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>